import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const FilePageTemplateIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="file-page-template" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59961 0.833334C1.59961 0.336278 2.00255 -0.0666656 2.49961 -0.0666656H12.4996C12.7486 -0.0666656 12.9739 0.0344272 13.1369 0.197804L18.1358 5.19676C18.2988 5.35964 18.3996 5.58471 18.3996 5.83333V19.1667C18.3996 19.6637 17.9967 20.0667 17.4996 20.0667H2.49961C2.00255 20.0667 1.59961 19.6637 1.59961 19.1667V0.833334ZM3.39961 1.73333H11.5996V5.33333C11.5996 6.10653 12.2264 6.73333 12.9996 6.73333H16.5996V18.2667H3.39961V1.73333ZM5.83311 4.93333C5.33605 4.93333 4.93311 5.33628 4.93311 5.83333C4.93311 6.33039 5.33605 6.73333 5.83311 6.73333H9.16644C9.6635 6.73333 10.0664 6.33039 10.0664 5.83333C10.0664 5.33628 9.6635 4.93333 9.16644 4.93333H5.83311ZM4.93311 9.99999C4.93311 9.50293 5.33605 9.09999 5.83311 9.09999H14.1664C14.6635 9.09999 15.0664 9.50293 15.0664 9.99999C15.0664 10.497 14.6635 10.9 14.1664 10.9H5.83311C5.33605 10.9 4.93311 10.497 4.93311 9.99999ZM5.83311 13.2667C5.33605 13.2667 4.93311 13.6696 4.93311 14.1667C4.93311 14.6637 5.33605 15.0667 5.83311 15.0667H14.1664C14.6635 15.0667 15.0664 14.6637 15.0664 14.1667C15.0664 13.6696 14.6635 13.2667 14.1664 13.2667H5.83311Z"
      />
    </IconBase>
  )
}
