import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const MocharyFullLogoIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase
      name="mochary-full-logo"
      viewBox="0 0 88 23"
      sx={{ width: 120, marginTop: '7px', marginLeft: '-15px' }}
      {...props}
    >
      <path
        d="M9.34496 4.04297L5.65945 10.3698L1.91339 4.04297H0V14.2234H2.17174V8.24577L5.10239 13.147H6.14385L9.09064 8.1143L9.10275 14.2234H11.2745L11.2462 4.04297H9.34496Z"
        fill="currentColor"
      />
      <path
        d="M19.9211 3.86646C16.7644 3.86646 14.4514 6.10959 14.4514 9.13331C14.4514 12.157 16.7644 14.3961 19.9211 14.3961C23.0778 14.3961 25.3949 12.1735 25.3949 9.13331C25.3949 6.09316 23.0657 3.86646 19.9211 3.86646ZM19.9211 12.3912C19.5011 12.4025 19.0832 12.3253 18.6938 12.1646C18.3045 12.0039 17.9519 11.7631 17.6582 11.4572C17.3645 11.1514 17.136 10.7871 16.9871 10.3872C16.8381 9.98732 16.772 9.56044 16.7927 9.13331C16.7776 8.70529 16.8474 8.27857 16.9979 7.87861C17.1484 7.47865 17.3766 7.11365 17.6687 6.80542C17.9609 6.49718 18.3111 6.25202 18.6985 6.08458C19.0858 5.91713 19.5023 5.83083 19.9231 5.83083C20.344 5.83083 20.7605 5.91713 21.1478 6.08458C21.5351 6.25202 21.8853 6.49718 22.1775 6.80542C22.4697 7.11365 22.6979 7.47865 22.8484 7.87861C22.9989 8.27857 23.0687 8.70529 23.0536 9.13331C23.0743 9.5608 23.008 9.98803 22.8589 10.3882C22.7097 10.7884 22.4809 11.1529 22.1867 11.4588C21.8926 11.7647 21.5395 12.0054 21.1496 12.1659C20.7598 12.3263 20.3415 12.403 19.9211 12.3912V12.3912Z"
        fill="currentColor"
      />
      <path
        d="M48.6785 8.05679H44.1373V4.04297H41.8202V14.2234H44.1373V10.0493H48.6785V14.2234H50.9956V4.04297H48.6785V8.05679Z"
        fill="currentColor"
      />
      <path
        d="M57.9427 4.04297L53.5024 14.2234H55.876L56.764 12.0418H61.4062L62.2943 14.2234H64.7163L60.2275 4.04297H57.9427ZM57.4866 10.2547L59.073 6.35595L60.6595 10.2547H57.4866Z"
        fill="currentColor"
      />
      <path
        d="M75.8495 7.7363C75.8495 5.45208 74.1784 4.03882 71.5061 4.03882H67.1747V14.2233H69.4917V11.3886H71.6191L73.5486 14.2233H76.0352L73.7908 10.9367C74.4175 10.686 74.9522 10.2435 75.321 9.67014C75.6897 9.09683 75.8745 8.42108 75.8495 7.7363V7.7363ZM71.3769 9.51109H69.4917V5.96151H71.3769C72.7938 5.96151 73.5083 6.61473 73.5083 7.7363C73.5083 8.85787 72.7938 9.51109 71.3769 9.51109Z"
        fill="currentColor"
      />
      <path
        d="M85.3842 4.04297L82.7119 8.56622L80.0396 4.04297H77.5813L81.4525 10.5875V14.2234H83.7696V10.6163L87.6569 4.04297H85.3842Z"
        fill="currentColor"
      />
      <path
        d="M34.7923 7.96226H32.3703C32.231 7.95334 32.0915 7.97355 31.9602 8.02164C31.8289 8.06974 31.7087 8.1447 31.607 8.2419C31.5053 8.3391 31.4242 8.45648 31.3688 8.5868C31.3134 8.71712 31.2848 8.85761 31.2848 8.99961C31.2848 9.14161 31.3134 9.2821 31.3688 9.41242C31.4242 9.54273 31.5053 9.66011 31.607 9.75732C31.7087 9.85452 31.8289 9.92948 31.9602 9.97757C32.0915 10.0257 32.231 10.0459 32.3703 10.037H34.7923C35.5 10.038 36.1785 10.3244 36.6793 10.8333C37.1801 11.3422 37.4625 12.0323 37.4646 12.7525V13.0853C37.4625 13.8056 37.1801 14.4957 36.6793 15.0046C36.1785 15.5135 35.5 15.7998 34.7923 15.8009H29.6738L27.4496 18.6151C27.4186 18.6131 27.3876 18.6131 27.3567 18.6151C27.0374 18.6151 26.7252 18.7115 26.4596 18.8921C26.1941 19.0726 25.9872 19.3293 25.8649 19.6296C25.7427 19.9298 25.7108 20.2603 25.7731 20.579C25.8354 20.8978 25.9891 21.1906 26.215 21.4204C26.4408 21.6503 26.7285 21.8068 27.0417 21.8702C27.3549 21.9336 27.6796 21.901 27.9746 21.7767C28.2697 21.6523 28.5218 21.4417 28.6993 21.1714C28.8767 20.9012 28.9714 20.5835 28.9714 20.2584C28.9713 20.1365 28.9564 20.0151 28.927 19.8969L30.5417 17.8962H34.7923C35.4118 17.8956 36.0251 17.7708 36.5972 17.5289C37.1693 17.287 37.689 16.9327 38.1265 16.4863C38.564 16.0399 38.9107 15.5102 39.1469 14.9273C39.3832 14.3445 39.5042 13.7199 39.5031 13.0894V12.7525C39.4999 11.4824 39.0025 10.2652 38.1196 9.36745C37.2367 8.46968 36.0403 7.96443 34.7923 7.96226V7.96226Z"
        fill="currentColor"
      />
      <path
        d="M38.3486 1.91077L36.6532 4.04298H32.3622C31.1139 4.04407 29.9169 4.54897 29.0338 5.44698C28.1507 6.34498 27.6535 7.56276 27.6514 8.83327V9.16604C27.6535 10.4369 28.1505 11.6551 29.0335 12.5538C29.9165 13.4524 31.1135 13.9583 32.3622 13.9604H34.7842C34.9299 13.9835 35.0787 13.9742 35.2204 13.9331C35.3622 13.8921 35.4935 13.8203 35.6055 13.7227C35.7174 13.6252 35.8072 13.5041 35.8688 13.3678C35.9304 13.2316 35.9623 13.0834 35.9623 12.9334C35.9623 12.7834 35.9304 12.6352 35.8688 12.4989C35.8072 12.3626 35.7174 12.2416 35.6055 12.144C35.4935 12.0464 35.3622 11.9746 35.2204 11.9336C35.0787 11.8926 34.9299 11.8833 34.7842 11.9063H32.3622C31.6538 11.9052 30.9747 11.6183 30.4738 11.1085C29.9729 10.5987 29.691 9.90756 29.6899 9.18659V8.85381C29.6921 8.13355 29.9744 7.44347 30.4752 6.93455C30.976 6.42563 31.6545 6.1393 32.3622 6.13822H37.4808L39.8341 3.19256L40.48 3.74718L41.1097 0.111328L37.7028 1.35615L38.3486 1.91077Z"
        fill="currentColor"
      />
    </IconBase>
  )
}
