import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

const spin = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const Spinning = styled('div')({
  animation: `${spin} 1s linear infinite`,
  width: 20,
  height: 20,
})

export const LoadingIcon = ({ className, color = 'currentColor', ...props }: OptionalIconBaseProps) => (
  <Spinning className={className}>
    <IconBase name="loading" viewBox="0 0 20 20" color={color} {...props}>
      <circle cx="10" cy="10" r="8" fill="none" stroke={color} strokeWidth="2" strokeDasharray="10, 30" />
    </IconBase>
  </Spinning>
)
