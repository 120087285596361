import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ExclamationCircleIcon = (props: OptionalIconBaseProps) => (
  <IconBase
    name="exclamation-circle"
    viewBox="0 0 20 20"
    sx={{ position: 'relative', top: '1px', paddingY: '0.5px' }}
    {...props}
  >
    <circle cx={10} cy={10} r={9.25} fill="white" strokeWidth={1.5} />
    <path d="M11.0664 5.80547C11.0982 5.23227 10.642 4.75 10.0679 4.75H9.9321C9.35801 4.75 8.90179 5.23227 8.93364 5.80547L9.2084 10.7512C9.23174 11.1713 9.57922 11.5 10 11.5C10.4208 11.5 10.7683 11.1713 10.7916 10.7512L11.0664 5.80547Z" />
    <path d="M10 15.25C10.6213 15.25 11.125 14.7463 11.125 14.125C11.125 13.5037 10.6213 13 10 13C9.37868 13 8.875 13.5037 8.875 14.125C8.875 14.7463 9.37868 15.25 10 15.25Z" />
  </IconBase>
)
