import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CheckCircleIcon = (props: OptionalIconBaseProps) => (
  <IconBase
    name="check-circle"
    viewBox="0 0 20 20"
    sx={{ position: 'relative', top: '1px', paddingY: '0.5px' }}
    {...props}
  >
    <circle cx={10} cy={10} r={9.5} fill="white" strokeWidth={1} />
    <path d="M6 10.1429L8.43478 13L14 8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </IconBase>
)
