import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'

type Booleanish = boolean | 'true' | 'false'

export type OptionalIconBaseProps = {
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
  style?: React.CSSProperties
  color?: string
  children?: React.ReactNode
  width?: string | number
  height?: string | number
  display?: string
  focusable?: Booleanish | 'auto'
  sx?: ThemeUIStyleObject
}

export type IconBaseProps = OptionalIconBaseProps & {
  name: string
  viewBox: string
}

export const IconBase = ({
  name,
  children,
  color = 'currentColor',
  width = '1em',
  height = '1em',
  display,
  ...restProps
}: IconBaseProps) => {
  return (
    <svg
      sx={{
        fill: color,
        stroke: color,
        strokeWidth: 0,
        width,
        height,
        display,
        ...restProps.sx,
      }}
      data-icon={name}
      {...restProps}
    >
      {children}
    </svg>
  )
}
